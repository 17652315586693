var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading || _vm.fetchingNodePools)?_c('v-skeleton-loader',{attrs:{"elevation":"6","type":"article@2"}}):_c('v-card',[_c('v-card-title',{staticClass:"flex-nowrap align-start"},[_c('router-link',{staticClass:"text-h6 text-decoration-none secondary--text mr-3",attrs:{"to":{
                    name: 'snapshot-overview',
                    params: _vm.routeParams()
                }}},[_c('span',[_vm._v(_vm._s(_vm.item.space_name))])]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","elevation":"0","fab":""}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(_vm._s(_vm.getAppSpaceType(_vm.item)))])],1)],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.item.instance_name)+" ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"xs":"4"}},[_c('v-card',{attrs:{"flat":"","color":"grey lighten-3","to":{
                            name: 'snapshot-files',
                            params: _vm.routeParams('file')
                        }}},[_c('v-card-title',[_c('v-icon',[_vm._v("folder")])],1),_c('v-card-text',{staticClass:"header-font"},[_vm._v("Files")])],1)],1),_c('v-col',{attrs:{"xs":"4"}},[_c('v-card',{attrs:{"flat":"","color":"grey lighten-3","to":{
                            name: 'snapshot-tables',
                            params: _vm.routeParams(),
                            query: _vm.$route.query
                        }}},[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-table-large")])],1),_c('v-card-text',{staticClass:"header-font"},[_vm._v("Tables")])],1)],1),_c('v-col',{attrs:{"xs":"4"}},[_c('v-card',{attrs:{"flat":"","color":"grey lighten-3","to":{
                            name: 'snapshot-applications',
                            params: _vm.routeParams(),
                            query: _vm.$route.query
                        }}},[_c('v-card-title',[_c('v-icon',[_vm._v("desktop_windows")])],1),_c('v-card-text',{staticClass:"header-font"},[_vm._v("Apps")])],1)],1)],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Quick Launch:")]),_c('v-card',{staticClass:"shepherd-quicklaunch",attrs:{"flat":"","color":"grey lighten-3"}},[_c('v-list-item',{attrs:{"two-line":"","to":{
                                name: 'app-open',
                                params: {
                                    oid: _vm.item.org_id,
                                    sid: _vm.item.space_id,
                                    iid: _vm.item.instance_id,
                                    snid: _vm.item.snid,
                                    aid: _vm.item.aid
                                }
                            }}},[_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.appIcon(_vm.item)}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mr-1 text-truncate secondary--text header-font"},[_vm._v(" "+_vm._s(_vm.item.long_id)+" ")]),_c('v-list-item-subtitle',[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.item.description))])])],1),_c('v-list-item-action',[(!_vm.runningDeployments.includes(parseInt(_vm.item.aid, 10)) && !_vm.startingDeployments.includes(parseInt(_vm.item.aid, 10)))?_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"primary"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("power_settings_new")])],1):_vm._e(),(_vm.startingDeployments.includes(parseInt(_vm.item.aid, 10)))?_c('stop-application',{attrs:{"appName":_vm.item.long_id,"appId":_vm.item.aid.toString(),"isShared":_vm.$store.getters['appStore/runningDeployments']
                                            .concat(_vm.$store.getters['appStore/startingDeployments'])
                                            .find(a => a.aid.toString() === _vm.item.aid.toString()).shared === 'true'}},[_c('v-btn',{attrs:{"fab":"","x-small":"","dark":"","color":"deep-orange lighten-2"}},[_c('v-progress-circular',{attrs:{"size":"18","color":"white","indeterminate":""}})],1)],1):_vm._e(),(_vm.runningDeployments.includes(parseInt(_vm.item.aid, 10)))?_c('stop-application',{attrs:{"appName":_vm.item.long_id,"appId":_vm.item.aid.toString(),"isShared":_vm.$store.getters['appStore/runningDeployments']
                                            .concat(_vm.$store.getters['appStore/startingDeployments'])
                                            .find(a => a.aid.toString() === _vm.item.aid.toString()).shared === 'true'}},[_c('v-btn',{attrs:{"fab":"","x-small":"","dark":"","color":"success"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("power_settings_new")])],1)],1):_vm._e()],1)],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-actions',[_c('span',{staticClass:"ml-2"},[_vm._v("Size:")]),_c('app-scale-info-menu',{attrs:{"app":_vm.item}},[_c('v-chip',{staticClass:"mx-2 pl-4",attrs:{"label":""}},[(_vm.item.active_resource === 'ncu')?[_c('v-icon',{attrs:{"small":"","left":"","color":"primary"}},[_vm._v("mdi-card-bulleted-outline")]),_vm._v(" "+_vm._s(`${_vm.item.ncu} CU`)+" ")]:[(_vm.getNodepoolFamily(_vm.currentNodePool(_vm.item.node_pool)) === 'cpu')?_c('v-icon',{attrs:{"small":"","left":"","color":"purple"}},[_vm._v(" mdi-memory ")]):_vm._e(),(_vm.getNodepoolFamily(_vm.currentNodePool(_vm.item.node_pool)) === 'gpu')?_c('v-icon',{attrs:{"small":"","left":"","color":"light-green"}},[_vm._v(" mdi-memory mdi-rotate-45 ")]):_vm._e(),(_vm.getNodepoolFamily(_vm.currentNodePool(_vm.item.node_pool)) === 'ssd')?_c('v-icon',{attrs:{"small":"","left":"","color":"orange"}},[_vm._v(" mdi-nas ")]):_vm._e(),_vm._v(" "+_vm._s(`${_vm.getNodepoolPrimaryParameter(_vm.currentNodePool(_vm.item.node_pool))}`)+" ")]],2)],1),_c('v-spacer'),(_vm.item.active_resource === 'ncu')?_c('v-chip',{staticClass:"mr-2 green--text text--darken-4",attrs:{"label":"","color":"green lighten-4"}},[_vm._v(" included ")]):_c('v-chip',{staticClass:"mr-2 amber--text text--darken-4",attrs:{"label":"","color":"amber lighten-4"}},[_vm._v(" "+_vm._s(_vm.currentNodePool(_vm.item.node_pool).credits_per_hour.toFixed(2))+" Credit/hour ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }